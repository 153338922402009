<template>
  <filter-engagements-block
    module-name="campaignPlanning"
    :only-rate-input="true"
    :is-filter-applied="isFilterApplied"
    @submit="handleSubmit"
  />
</template>

<script>
const FilterEngagementsBlock = () => import(/* webpackChunkName: "filter-engagement-campaign-planning" */ "@/blocks/common/filters/FilterEngagements.vue")

export default {
  name: "FilterEngagements",

  components: {
    FilterEngagementsBlock
  },

  computed: {
    // Used to bold the filter name text
    isFilterApplied() {
      return Boolean(this.$store.getters[`campaignPlanning/findFilterByType`]("engagements"))
    }
  },

  methods: {
    handleSubmit(data) {
      this.$store.dispatch(`campaignPlanning/replaceFilter`, data)
    }
  }
}
</script>
